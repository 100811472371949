import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Li from '../components/Li/Li';
import { Seo } from '../components/Seo';
import Layout from '../layouts/layout';

const LegalNotesPage = props => {
    const { t } = useI18next();

    return <Layout location={props.location}>
        <div className="container p-0" style={{ margin: '60px auto' }}>
            <h1 style={{ fontSize: '1.2rem' }}><Trans>Legal company information</Trans></h1>
            <Li>Technology & Cognition LAB S.r.l.</Li>
            <Li>{t('legal-notes-page:capital')}</Li>
            <Li><Trans>Main office</Trans>: Torino – Corso Re Umberto, 10 - 10121 - Italy</Li>
            <Li><Trans>VAT</Trans>: 11607460018</Li>
            <Li><Trans>REA</Trans>: TO-1226976</Li>
        </div>
    </Layout>;
};

export default LegalNotesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "legal-notes-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'legal-notes-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Legal notes';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};

